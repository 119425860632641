import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

export const Navigation = () => {
  const location = useLocation();

  return (
    <nav className="fixed top-0 left-0 right-0 bg-navy-900/90 text-white z-50 backdrop-blur-sm">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <RouterLink to="/" className="flex items-center">
            <img src="/images/logo.png" alt="Stilwater Logo" className="h-8" />
          </RouterLink>
          <div className="flex space-x-8">
            <RouterLink
              to="/services"
              className={`cursor-pointer hover:text-purple-600 transition-colors ${
                location.pathname === '/services' ? 'text-purple-600' : ''
              }`}
            >
              What we do
            </RouterLink>
            <RouterLink
              to="/who-we-are"
              className={`cursor-pointer hover:text-purple-600 transition-colors ${
                location.pathname === '/who-we-are' ? 'text-purple-600' : ''
              }`}
            >
              Why
            </RouterLink>
            <RouterLink
              to="/about"
              className={`cursor-pointer hover:text-purple-600 transition-colors ${
                location.pathname === '/about' ? 'text-purple-600' : ''
              }`}
            >
              About
            </RouterLink>
            <RouterLink
              to="/contact"
              className={`cursor-pointer hover:text-purple-600 transition-colors ${
                location.pathname === '/contact' ? 'text-purple-600' : ''
              }`}
            >
              Contact
            </RouterLink>
          </div>
        </div>
      </div>
    </nav>
  );
};
import React from 'react';

export const Contact = () => {
  React.useEffect(() => {
    // Load LACRM embed script
    const script = document.createElement('script');
    script.src = 'https://embed.lessannoyingcrm.com/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section id="contact" className="section bg-navy-900 text-white">
      <div className="container max-w-4xl">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-16">Get Started</h2>
        <div className="bg-white/10 rounded-lg p-6">
          <blockquote 
            className="lacrm-embed" 
            data-embed-height="650" 
            data-embed-show-header="false"
          >
            <a href="https://forms.lessannoyingcrm.com/view/3995038017349490543996420768088">
              Reach out to a Stilwater DreamWeaver:
            </a>
          </blockquote>
        </div>
      </div>
    </section>
  );
};
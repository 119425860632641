import React from 'react';
import { ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export const Hero = () => {
  const navigate = useNavigate();

  return (
    <section className="min-h-screen flex items-center bg-navy-900 text-white section">
      <div className="container">
        <img 
          src="/images/logo.png" 
          alt="Stilwater Logo" 
          className="w-48 mb-8"
        />
        <div className="max-w-3xl">
          <h1 className="text-4xl md:text-6xl font-bold mb-6">
            Human-first AI <br />
            <span className="text-purple-600">Automate to Thrive</span>
          </h1>
          <p className="text-lg md:text-xl mb-4 text-gray-300">
            Adopting AI is a people opportunity... not a technology problem
          </p>
          <p className="text-lg md:text-xl mb-4 text-gray-300">
            Are you hiring an AI Assistant this year?
          </p>
          <p className="text-lg md:text-xl mb-8 text-gray-300">
            Let us build one for you
          </p>
          <button 
            onClick={() => navigate('/contact')} 
            className="btn btn-primary inline-flex items-center gap-2"
          >
            Start Your AI Journey <ArrowRight size={20} />
          </button>
        </div>
      </div>
    </section>
  );
};
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Users, Lightbulb, Target } from 'lucide-react';

export const About = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section id="about" className="section bg-white">
      <div className="container">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-16">Who We Are</h2>
        <div
          ref={ref}
          className={`grid md:grid-cols-3 gap-8 transition-all duration-700 ${
            inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
          }`}
        >
          <div className="text-center p-6">
            <Users className="w-12 h-12 text-purple-600 mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-3">People-Centric</h3>
            <p className="text-gray-600">
              We put your team first, ensuring AI adoption enhances rather than replaces human potential.
            </p>
          </div>
          <div className="text-center p-6">
            <Lightbulb className="w-12 h-12 text-purple-600 mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-3">Innovation Focused</h3>
            <p className="text-gray-600">
              Cutting-edge AI solutions tailored to your unique business challenges.
            </p>
          </div>
          <div className="text-center p-6">
            <Target className="w-12 h-12 text-purple-600 mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-3">Results Driven</h3>
            <p className="text-gray-600">
              Measurable outcomes and tangible improvements in your business processes.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
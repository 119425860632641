import React from 'react';
import { Mail, Phone, MapPin } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export const Footer = () => {
  const navigate = useNavigate();
  
  return (
    <footer className="bg-navy-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-2 gap-8 items-start">
          <div>
            <h3 className="text-xl font-semibold mb-4">Get in Touch</h3>
            <div className="space-y-3">
              <div className="flex items-center gap-2">
                <Mail className="w-5 h-5 text-purple-600" />
                <a href="mailto:info@stilwater.ai" className="hover:text-purple-600 transition-colors">
                  info@stilwater.ai
                </a>
              </div>
              <div className="flex items-center gap-2">
                <Phone className="w-5 h-5 text-purple-600" />
                <a href="tel:+14167090701" className="hover:text-purple-600 transition-colors">
                  +1 416 709 0701
                </a>
              </div>
              <div className="flex items-center gap-2">
                <MapPin className="w-5 h-5 text-purple-600" />
                <span>Huntsville, Ontario, Canada</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start">
            <h3 className="text-xl font-semibold mb-4">Ready to Get Started?</h3>
            <p className="mb-4">Let us help you transform your business with AI.</p>
            <button 
              onClick={() => navigate('/contact')}
              className="btn btn-primary"
            >
              Contact Us
            </button>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-white/10 text-center text-sm text-white/60">
          <p>&copy; {new Date().getFullYear()} Stilwater. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};
import React from 'react';
import { useInView } from 'react-intersection-observer';

export const WhoWeAre = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section id="who-we-are" className="section bg-white">
      <div className="container max-w-4xl">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-16">Why We're Here</h2>
        <div
          ref={ref}
          className={`space-y-6 transition-all duration-700 ${
            inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
          }`}
        >
          <div className="prose prose-lg max-w-none">
            <p className="text-xl font-semibold text-purple-600 mb-8">
              Stilwater - Human-first AI | Automate to Thrive
            </p>
            
            <p className="text-lg mb-8 italic">
              AI is a people Opportunity, not a technology problem.
            </p>

            <p className="mb-8">
              At Stilwater, our goal is to empower small and medium businesses in Canada to thrive. 
              We do this by educating and empowering the teams working at these businesses on how 
              best to adopt and hire AI Intelligent Automated Assistants.
            </p>

            <p className="mb-8">
              We also build and maintain these Intelligent Assistants and train team members on their use.
            </p>

            <h3 className="text-2xl font-semibold mb-4">Our Goals</h3>
            <p className="mb-4">Our goal with each client is to allow them to succeed in any or all of the following areas:</p>
            <ul className="list-decimal pl-6 mb-8 space-y-2">
              <li>Increased revenue</li>
              <li>Decreased cost</li>
              <li>Improved employee effectiveness</li>
              <li>Improved employee productivity</li>
              <li>Improved job satisfaction and enjoyment</li>
            </ul>

            <p className="mb-8">
              We take a human-first approach to ensure teams are READY to become more effective, 
              efficient AND happier at their jobs.
            </p>

            <p className="mb-8">
              Our team empowerment experience and business process knowledge blended with AI 
              Intelligent Agent development brings full lifecycle solutions to our clients.
            </p>

            <div className="bg-gray-50 p-6 rounded-lg mb-8">
              <h3 className="text-2xl font-semibold mb-4">Our Mission</h3>
              <p>
                Our Mission is to put people first and together we strive to empower the productivity 
                and profitability of Medium and Small businesses and thus the economy.
              </p>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="text-2xl font-semibold mb-4">Our Vision</h3>
              <p>
                Our Vision is to drive the productivity of Canadians to be top 5 in the world as 
                expressed by GDP per capita.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
import React, { useState } from 'react';
import { MessageCircle, X } from 'lucide-react';

export const ChatWidget = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="fixed bottom-6 right-6 z-50">
      {isOpen ? (
        <div className="bg-white rounded-lg shadow-xl w-[350px] h-[500px] flex flex-col">
          <div className="bg-navy-900 text-white p-4 rounded-t-lg flex justify-between items-center">
            <span className="font-semibold">Chat with us</span>
            <button
              onClick={() => setIsOpen(false)}
              className="text-white/80 hover:text-white"
            >
              <X size={20} />
            </button>
          </div>
          <div className="flex-1 p-4">
            {/* Voiceflow chat integration would go here */}
            <div className="text-center text-gray-500 mt-4">
              Chat widget placeholder
            </div>
          </div>
        </div>
      ) : (
        <button
          onClick={() => setIsOpen(true)}
          className="bg-purple-600 text-white p-4 rounded-full shadow-lg hover:bg-purple-700 transition-colors"
        >
          <MessageCircle size={24} />
        </button>
      )}
    </div>
  );
};
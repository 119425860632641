import React from 'react';
import { ClipboardCheck, Workflow, Bot, Megaphone, GraduationCap } from 'lucide-react';

const ServiceCard = ({ icon: Icon, title, description }: {
  icon: React.ElementType;
  title: string;
  description: string;
}) => (
  <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
    <Icon className="w-12 h-12 text-purple-600 mb-4" />
    <h3 className="text-xl font-semibold mb-3">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

export const Services = () => {
  return (
    <section id="services" className="section bg-gray-50">
      <div className="container">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-16">Our Services</h2>
        <div className="grid md:grid-cols-3 gap-8">
          <ServiceCard
            icon={Megaphone}
            title="General Education"
            description="Hit the Contact in the menu to engage our Chief DreamWeaver in public speaking events to demystify AI."
          />
          <ServiceCard
            icon={GraduationCap}
            title="AI Bootcamp"
            description="Group education taking a crawl, saunter, sprint approach. Three sessions that build on each other to empower your team to hire, use and invent new AI assistants."
          />
          <ServiceCard
            icon={ClipboardCheck}
            title="Organizational AI Assessment"
            description="Comprehensive evaluation of your AI readiness and opportunities for automation."
          />
          <ServiceCard
            icon={Workflow}
            title="Team Empowerment to Adopt AI"
            description="End-to-end guidance in implementing AI solutions across your organization."
          />
          <ServiceCard
            icon={Bot}
            title="Custom AI Assistant Development"
            description="Tailored AI assistants designed to enhance your specific business processes."
          />
        </div>
      </div>
    </section>
  );
};